import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined
  );
};

const getHistory = (params) => {
  return axiosClient.get("action-logs", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export { getHistory };
