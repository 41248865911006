import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined
  );
};

const getLocations = (params) => {
  return axiosClient.get("locations", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getLocationDetail = (id) => {
  return axiosClient.get("locations/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export { getLocations, getLocationDetail };
