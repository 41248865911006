import axiosClient from "../axiosClient"

const login = (data) => {
    return axiosClient.post("sign-in", data)
}

const resetPassword = (params) => {
    return axiosClient.get("authentication/reset-password", {params})
}

const changePassword = (token, data) => {
    return axiosClient.post("authentication/change-password", data, {
        headers: {
            Authorization: "Bearer " + token
        }
    })
}

const logout = () => {
    return axiosClient.post(`sign-out?token=${localStorage?.auth && JSON.parse(localStorage.auth)?.token}`)
}

// const cancelRequestLogin = () => {
//     return axiosClient.post(`authentication/cancel-login-other-devices?token=${localStorage?.auth && JSON.parse(localStorage.auth)?.token}`)
// }

export {
    login,
    resetPassword,
    changePassword,
    logout,
    // cancelRequestLogin
}