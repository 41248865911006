import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/Auth/authSlice";
import userReducer from "../features/user/userSlice";
import memberReducer from "../features/member/memberSlice";
import verifyReducer from "../features/verify/verifySlice";
import newsReducer from "../features/news/newsSlice";
import locationReducer from "../features/location/locationSlice";
import notifyReducer from "../features/notify/notifySlice";
import autoEmailReducer from "../features/autoEmail/autoEmailSlice";
import requestReducer from "../features/request/requestSlice";
import historyReducer from "../features/history/historySlice";
import dashboardReducer from "../features/dashboard/dashboardSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: userReducer,
    members: memberReducer,
    verify: verifyReducer,
    news: newsReducer,
    locations: locationReducer,
    notify: notifyReducer,
    autoEmail: autoEmailReducer,
    request: requestReducer,
    history: historyReducer,
    dashboard: dashboardReducer,
  },
});
