import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined
  );
};

const getNews = (params) => {
  return axiosClient.get("posts", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getNewsDetail = (id) => {
  return axiosClient.get("posts/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getNewsDate = (params) => {
  return axiosClient.get("posts-static-date", {
    params,
    headers: {
      Authorization: "Bearer " + getToken()
    }
  })
}

const getNewsMonth = (params) => {
  return axiosClient.get("posts-static-month", {
    params,
    headers: {
      Authorization: "Bearer " + getToken()
    }
  })
}

const postNews = (data) => {
  return axiosClient.post("posts", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putNews = (data) => {
  return axiosClient.put("posts/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export { getNews, getNewsDetail, postNews, putNews, getNewsDate, getNewsMonth };
