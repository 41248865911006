import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined
  );
};

const getAutoEmail = (params) => {
  return axiosClient.get("email-histories", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getAutoEmailDetail = (id) => {
  return axiosClient.get("email-histories/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export { getAutoEmail, getAutoEmailDetail };
