import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getAutoEmail, getAutoEmailDetail } from "./autoEmailAPI";
const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
  type: "list",
};

export const getAutoEmailAsync = createAsyncThunk(
  "autoEmail",
  async (params, thunkAPI) => {
    try {
      const response = await getAutoEmail(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getAutoEmailDetailAsync = createAsyncThunk(
  "AutoEmailDetail",
  async (id, thunkAPI) => {
    try {
      const response = await getAutoEmailDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const autoEmailSlice = createSlice({
  name: "autoEmail",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearAutoEmailDetail: (state) => {
      state.detail = undefined;
    },
    clearAutoEmail: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getAutoEmailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAutoEmailAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getAutoEmailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getAutoEmail",
          };
        }
      })
      .addCase(getAutoEmailDetailAsync.pending, (state) => {
        state.status = "detail_loading";
      })
      .addCase(getAutoEmailDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload.data;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getAutoEmailDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getAutoEmailDetail",
          };
        }
      });
  },
});
export const selectAutoEmail = (state) => state.autoEmail.list;
export const selectAutoEmailDetail = (state) => state.autoEmail.detail;
export const autoEmailStatus = (state) => state.autoEmail.status;
export const autoEmailError = (state) => state.autoEmail.error;
export const autoEmailType = (state) => state.autoEmail.type;

export const { clearAutoEmailDetail, clearAutoEmail, setIdleStatus } =
  autoEmailSlice.actions;

export default autoEmailSlice.reducer;
