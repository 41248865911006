import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined
  );
};

const getTopMembersByPosts = (params) => {
  return axiosClient.get("top-members-by-posts", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getTopMembersByRequests = (params) => {
  return axiosClient.get("top-members-by-requests", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getMemberChart = (params) => {
  return axiosClient.get("daily-member-reports", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getHomeNewsChart = (params) => {
  return axiosClient.get("monthly-post-reports", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getOtherNewsChart = (params) => {
  return axiosClient.get("daily-post-reports", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  getTopMembersByPosts,
  getTopMembersByRequests,
  getMemberChart,
  getHomeNewsChart,
  getOtherNewsChart,
};
