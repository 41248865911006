import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  getTopMembersByPosts,
  getTopMembersByRequests,
  getMemberChart,
  getHomeNewsChart,
  getOtherNewsChart,
} from "./dashboardAPI";

const initialState = {
  topMemberByPosts: undefined,
  topMembersByRequests: undefined,
  memberChart: undefined,
  homeNewsChart: undefined,
  otherNewsChart: undefined,
  status: "idle",
  error: undefined,
};

export const getTopMemberByPostsAsync = createAsyncThunk(
  "topMemberByPosts",
  async (params, thunkAPI) => {
    try {
      const response = await getTopMembersByPosts(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getTopMemberByRequestsAsync = createAsyncThunk(
  "topMemberByRequests",
  async (params, thunkAPI) => {
    try {
      const response = await getTopMembersByRequests(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getMemberChartAsync = createAsyncThunk(
  "memberChart",
  async (params, thunkAPI) => {
    try {
      const response = await getMemberChart(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getHomeNewsChartAsync = createAsyncThunk(
  "homeNewsChart",
  async (params, thunkAPI) => {
    try {
      const response = await getHomeNewsChart(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getOtherNewsChartAsync = createAsyncThunk(
  "otherNewsChart",
  async (params, thunkAPI) => {
    try {
      const response = await getOtherNewsChart(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboards",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getTopMemberByPostsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTopMemberByPostsAsync.fulfilled, (state, action) => {
        state.topMemberByPosts = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getTopMemberByPostsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getPostsDashboards",
          };
        }
      })
      .addCase(getTopMemberByRequestsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTopMemberByRequestsAsync.fulfilled, (state, action) => {
        state.topMemberByRequests = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getTopMemberByRequestsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getRequestsDashboards",
          };
        }
      })
      .addCase(getMemberChartAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMemberChartAsync.fulfilled, (state, action) => {
        state.memberChart = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getMemberChartAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getMemberChartDashboards",
          };
        }
      })
      .addCase(getHomeNewsChartAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getHomeNewsChartAsync.fulfilled, (state, action) => {
        state.homeNewsChart = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getHomeNewsChartAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getHomeNewsChartDashboards",
          };
        }
      })
      .addCase(getOtherNewsChartAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getOtherNewsChartAsync.fulfilled, (state, action) => {
        state.otherNewsChart = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getOtherNewsChartAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getOtherNewsChartDashboards",
          };
        }
      });
  },
});
export const selectTopMemberByPosts = (state) =>
  state.dashboard.topMemberByPosts;
export const selectTopMemberByRequests = (state) =>
  state.dashboard.topMemberByRequests;
export const selectMemberChart = (state) => state.dashboard.memberChart;
export const selectHomeNewsChart = (state) => state.dashboard.homeNewsChart;
export const selectOtherNewsChart = (state) => state.dashboard.otherNewsChart;
export const dashboardsStatus = (state) => state.dashboard.status;
export const dashboardsError = (state) => state.dashboard.error;

export const { setIdleStatus } = dashboardSlice.actions;

export default dashboardSlice.reducer;
