import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
    getMembers,
    getMemberDetail,
    putMember,
    postMember,
    putMemberStatus,
    putPasswordForMember,
    putMemberPassword,
    resetPasswordForMember,
} from './memberAPI';

const initialState = {
    members: undefined,
    detail: undefined,
    status: 'idle',
    error: undefined,
};

export const getMembersAsync = createAsyncThunk(
    'members',
    async (params, thunkAPI) => {
      try{
        const response = await getMembers(params);
        // The value we return becomes the `fulfilled` action payload
        return response.data;
      } catch(error){
        return thunkAPI.rejectWithValue(error.response.data)
      }
    }
);
export const getMemberDetailAsync = createAsyncThunk(
    'memberDetail',
    async (id, thunkAPI) => {
      try{
        const response = await getMemberDetail(id);
        // The value we return becomes the `fulfilled` action payload
        return response.data;
      } catch(error){
        return thunkAPI.rejectWithValue(error.response.data)
      }
    }
);
export const postMemberAsync = createAsyncThunk(
    'postMember',
    async (data, thunkAPI) => {
      try{
        const response = await postMember(data);
        return response.data;
      } catch(error){
        return thunkAPI.rejectWithValue(error.response.data)
      }
    }
);
export const putMemberAsync = createAsyncThunk(
    'putMember',
    async (data, thunkAPI) => {
      try{
        const response = await putMember(data);
        return response.data;
      } catch(error){
        return thunkAPI.rejectWithValue(error.response.data)
      }
    }
);
export const putMemberStatusAsync = createAsyncThunk(
    'putMemberStatus',
    async (data, thunkAPI) => {
      try{
        const response = await putMemberStatus(data.id, data.values);
        return response.data;
      } catch(error){
        return thunkAPI.rejectWithValue(error.response.data)
      }
    }
);
export const putMemberPasswordAsync = createAsyncThunk(
    'putMemberPassword',
    async (data, thunkAPI) => {
      try{
        const response = await putMemberPassword(data);
        localStorage.removeItem("auth")
        localStorage.removeItem("token")
        window.location.replace("/")
        return response.data;
      } catch(error){
        return thunkAPI.rejectWithValue(error.response)
      }
    }
);
export const putPasswordForMemberAsync = createAsyncThunk(
    'putPasswordForMember',
    async (data, thunkAPI) => {
      try{
        const response = await putPasswordForMember(data.id, data.values);
        return response.data;
      } catch(error){
        return thunkAPI.rejectWithValue(error.response.data)
      }
    }
);
  
export const resetPasswordForMemberAsync = createAsyncThunk(
  'resetPassword',
  async (id, thunkAPI) => {
    try{
      const response = await resetPasswordForMember(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch(error){
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
);
export const memberSlice = createSlice({
    name: 'members',
    initialState,
    reducers: {
      setIdleStatus: state => {
        state.status = "idle"
      },
      clearMemberDetail: state => {
        state.detail = undefined;
      },
      clearMembers: state => {
        state.members = undefined;
      }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
      builder
        .addCase(getMembersAsync.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(getMembersAsync.fulfilled, (state, action) => {
          state.members = action.payload
          state.status = 'idle';
          state.error = undefined;
        })
        .addCase(getMembersAsync.rejected, (state, action) => {
          state.status = 'idle';
          if(action?.error?.name !== "AbortError"){
            message.error("Lấy dữ liệu thất bại!")
            state.error = {
              payload: action.payload,
              title: "getMembers"
            }
          }
        })
        .addCase(getMemberDetailAsync.pending, (state) => {
          state.status = 'detail_loading';
        })
        .addCase(getMemberDetailAsync.fulfilled, (state, action) => {
          state.detail = action.payload.data
          state.status = 'idle';
          state.error = undefined;
        })
        .addCase(getMemberDetailAsync.rejected, (state, action) => {
          state.status = 'idle';
          if(action?.error?.name !== "AbortError"){
            message.error("Lấy dữ liệu thất bại!")
            state.error = {
              payload: action.payload,
              title: "getMember"
            }
          }
        })
        .addCase(postMemberAsync.pending, (state) => {
          state.status = 'detail_loading';
        })
        .addCase(postMemberAsync.fulfilled, (state, action) => {
          state.detail = action.payload
          state.status = 'done';
          state.error = undefined;
        })
        .addCase(putMemberAsync.pending, (state) => {
            state.status = 'update_loading';
        })
        .addCase(putMemberAsync.fulfilled, (state, action) => {
          state.detail = action.payload.data
          message.success("Cập nhật người dùng thành công!")
          state.status = 'done';
          state.error = undefined;
        })
        .addCase(putMemberAsync.rejected, (state, action) => {
          state.status = 'idle';
          if(action?.error?.name !== "AbortError"){
            message.error("Cập nhật người dùng thất bại!")
            state.error = {
              payload: action.payload,
              title: "putMember"
            }
          }
        })
        .addCase(putMemberStatusAsync.pending, (state) => {
          state.status = 'update_status_loading';
        })
        .addCase(putMemberStatusAsync.fulfilled, (state, action) => {
          console.log(state.detail)
          console.log(action.payload)

          state.detail = action.payload
          state.status = 'done';
          state.error = undefined;
        })
        .addCase(putMemberStatusAsync.rejected, (state, action) => {
          state.status = 'idle';
          if(action?.error?.name !== "AbortError"){
            message.error("Đổi trạng thái thất bại!")
            state.error = {
              payload: action.payload,
              title: "putMemberStatus"
            }
          }
        })
        .addCase(putMemberPasswordAsync.pending, (state) => {
          state.status = 'update_password_loading';
        })
        .addCase(putMemberPasswordAsync.fulfilled, (state, action) => {
          state.detail = action.payload
          state.status = 'done';
          state.error = undefined;
        })
        .addCase(putMemberPasswordAsync.rejected, (state, action) => {
          state.status = 'idle';
          if(action?.error?.name !== "AbortError"){
            if(action?.payload?.status === 403){
              const data = action.payload.data[0]
              if(data.message === "Không đúng mật khẩu"){
                message.error("Không đúng mật khẩu!")  
              }
            }
            else{
              message.error("Đổi mật khẩu thất bại!")
            }
            state.error = {
              payload: action.payload,
              title: "putMemberPassword"
            }
          }
        })
        .addCase(putPasswordForMemberAsync.pending, (state) => {
          state.status = 'update_other_password_loading';
        })
        .addCase(putPasswordForMemberAsync.fulfilled, (state, action) => {
          state.detail = action.payload
          state.status = 'done';
          state.error = undefined;
        })
        .addCase(putPasswordForMemberAsync.rejected, (state, action) => {
          state.status = 'idle';
          if(action?.error?.name !== "AbortError"){
            message.error("Đổi mật khẩu thất bại!")
            state.error = {
              payload: action.payload,
              title: "putOtherMemberPassword"
            }
          }
        })

        .addCase(resetPasswordForMemberAsync.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(resetPasswordForMemberAsync.fulfilled, (state, action) => {
          state.detail = action.payload
          message.success("Khôi phúc mật khẩu thành công!")
          state.status = 'done';
          state.error = undefined;
        })
        .addCase(resetPasswordForMemberAsync.rejected, (state, action) => {
          state.status = 'idle';
          if(action?.error?.name !== "AbortError"){
            message.error("Khôi phục mật khẩu thất bại!")
            state.error = {
              payload: action.payload,
              title: "resetPassword"
            }
          }
        })
    },
});
export const selectMembers = state => state.members.members
export const selectMemberDetail = state => state.members.detail
export const membersStatus = state => state.members.status
export const membersError = state => state.members.error


export const { clearMemberDetail, clearMembers, setIdleStatus } = memberSlice.actions;
  
export default memberSlice.reducer;
  
