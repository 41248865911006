import {
  createAsyncThunk,
  // createAsynoticationThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { message } from "antd";
import { fetchAllNoti, getNotiDetail, getNotis, putNoti } from "./notifyAPI";

const initialState = {
  list: {
    data: [],
    total: 0,
  },
  detail: undefined,
  status: "idle",
  error: undefined,
  //bell
  countNoti: 0,
  listNoti: [],
  loadingNoti: false,
  errorNoti: false,
  totalUnread: 1,
  totalNotify: 10,
};

export const getNotisAsync = createAsyncThunk(
  "notification",
  async (params, thunkAPI) => {
    try {
      const response = await getNotis(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getNotiDetailAsync = createAsyncThunk(
  "notificationDetail",
  async (id, thunkAPI) => {
    try {
      const response = await getNotiDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putNotiAsync = createAsyncThunk(
  "putNoti",
  async (data, thunkAPI) => {
    try {
      const response = await putNoti(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchAllNotiAsync = createAsyncThunk(
  "fetchServiceNoti",
  async (data, thunkAPI) => {
    try {
      const response = await fetchAllNoti(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const loadMoreNotiAsync = createAsyncThunk(
  "loadMoreNoti",
  async (data, thunkAPI) => {
    try {
      const response = await fetchAllNoti(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const notifySlice = createSlice({
  name: "notify",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearNotiDetail: (state) => {
      state.detail = undefined;
    },
    clearNotis: (state) => {
      state.list = undefined;
    },
    updateNotiDetail: (state, action) => {
      state.detail = action.payload;
    },
    updateCountNoti: (state, action) => {
      state.countNoti = action.payload;
    },
    updateTotal: (state, action) => {
      state.totalNotify = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // inoticationluding actions generated by createAsynoticationThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getNotisAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getNotisAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getNotisAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getNotis",
          };
        }
      })

      .addCase(fetchAllNotiAsync.pending, (state) => {
        state.loadingNoti = "loading";
      })
      .addCase(fetchAllNotiAsync.fulfilled, (state, action) => {
        state.listNoti = action.payload.data;
        state.loadingNoti = "idle";
        state.errorNoti = undefined;
        state.totalUnread = action.payload.totalUnread;
        state.totalNotify = action.payload.total;
      })
      .addCase(fetchAllNotiAsync.rejected, (state, action) => {
        state.listNoti = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.errorNoti = {
            payload: action.payload,
            title: "fetchServiceNoti",
          };
        }
      })

      .addCase(loadMoreNotiAsync.pending, (state) => {
        state.loadingNoti = "loading";
      })
      .addCase(loadMoreNotiAsync.fulfilled, (state, action) => {
        state.listNoti = [...state.listNoti, ...action.payload.data];
        state.loadingNoti = "idle";
        state.errorNoti = undefined;
        state.totalUnread = action.payload.totalUnread;
        state.totalNotify = action.payload.total;
      })
      .addCase(loadMoreNotiAsync.rejected, (state, action) => {
        state.listNoti = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.errorNoti = {
            payload: action.payload,
            title: "fetchServiceNoti",
          };
        }
      })

      .addCase(getNotiDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getNotiDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getNotiDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getNotiDetail",
          };
        }
      })
      .addCase(putNotiAsync.pending, (state) => {
        state.status = "update_loading";
      })
      .addCase(putNotiAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "done";
        state.error = undefined;
        message.success("Cập nhật thành công!");
      })
      .addCase(putNotiAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật thất bại!");
          state.error = {
            payload: action.payload,
            title: "putNoti",
          };
        }
      });
  },
});

export const selectNotis = (state) => state.notify.list;
export const selectNotiDetail = (state) => state.notify.detail;
export const notiStatus = (state) => state.notify.status;
export const notiError = (state) => state.notify.error;

export const {
  clearNotiDetail,
  clearNotis,
  setIdleStatus,
  updateNotiDetail,
  updateCountNoti,
  updateTotal,
} = notifySlice.actions;

export default notifySlice.reducer;
