import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getNews, getNewsDetail, postNews, putNews } from "./newsAPI";
const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
  type: "list",
};

export const getNewsAsync = createAsyncThunk(
  "news",
  async (params, thunkAPI) => {
    try {
      const response = await getNews(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getNewsDetailAsync = createAsyncThunk(
  "newsDetail",
  async (id, thunkAPI) => {
    try {
      const response = await getNewsDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const postNewsAsync = createAsyncThunk(
  "postNews",
  async (data, thunkAPI) => {
    try {
      const response = await postNews(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const putNewsAsync = createAsyncThunk(
  "putNews",
  async (data, thunkAPI) => {
    try {
      const response = await putNews(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearNewsDetail: (state) => {
      state.detail = undefined;
    },
    clearNews: (state) => {
      state.list = undefined;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getNewsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getNewsAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getNewsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getNews",
          };
        }
      })
      .addCase(getNewsDetailAsync.pending, (state) => {
        state.status = "detail_loading";
      })
      .addCase(getNewsDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload.data;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getNewsDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getNews",
          };
        }
      })
      .addCase(postNewsAsync.pending, (state) => {
        state.status = "detail_loading";
      })
      .addCase(postNewsAsync.fulfilled, (state, action) => {
        state.detail = action.payload.data;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(putNewsAsync.pending, (state) => {
        state.status = "update_loading";
      })
      .addCase(putNewsAsync.fulfilled, (state, action) => {
        state.detail = action.payload.data;
        message.success("Cập nhật thành công!");
        state.status = "done";
        state.error = undefined;
      })
      .addCase(putNewsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật thất bại!");
          state.error = {
            payload: action.payload,
            title: "putNews",
          };
        }
      });
  },
});
export const selectNews = (state) => state.news.list;
export const selectNewsDetail = (state) => state.news.detail;
export const newsStatus = (state) => state.news.status;
export const newsError = (state) => state.news.error;
export const newsType = (state) => state.news.type;

export const { clearNewsDetail, clearNews, setIdleStatus, setType } = newsSlice.actions;

export default newsSlice.reducer;
