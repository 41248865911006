import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getHistory } from "./historytAPI";
const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
};

export const getHistoryAsync = createAsyncThunk(
  "history",
  async (params, thunkAPI) => {
    try {
      const response = await getHistory(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearHistory: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getHistoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getHistoryAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getHistoryAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getHistory",
          };
        }
      })
  },
});
export const selectHistory = (state) => state.history.list;
export const historyStatus = (state) => state.history.status;
export const historyError = (state) => state.history.error;

export const { clearHistory, setIdleStatus } = historySlice.actions;

export default historySlice.reducer;
