import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined;
}

const getMembers = (params) => {
    return axiosClient.get("members", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getMemberStatistic = (params) => {
    return axiosClient.get("members-statistic", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getMemberDate = (params) => {
    return axiosClient.get("members-static-date", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getMemberMonth = (params) => {
    return axiosClient.get("members-static-month", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getMemberDetail = (id) => {
    return axiosClient.get("members/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postMember = (data) => {
    return axiosClient.post("members", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putMember = (data) => {
    return axiosClient.put("members/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putMemberStatus = (memberCredentialId, data) => {
    return axiosClient.put(`/members/${memberCredentialId}/status`, data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putMemberPassword = (data) => {
    return axiosClient.post("members", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putPasswordForMember = (id, data) => {
    // return axiosClient.put("users-password/" + id, data, {
    //     headers: {
    //         Authorization: "Bearer " + getToken()
    //     }
    // })
}

const resetPasswordForMember = (id) => {
    return axiosClient.patch(`member-credentials/reset-password?memberCredentialId=${id}`,null, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
export {
    getMembers,
    getMemberDetail,
    postMember,
    putMember,
    putMemberStatus,
    putMemberPassword,
    putPasswordForMember,
    resetPasswordForMember,
    getMemberStatistic,
    getMemberDate,
    getMemberMonth,
}