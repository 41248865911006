import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getRequestDetail, getRequests } from "./requestAPI";
const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
  type: "list",
};

export const getRequestsAsync = createAsyncThunk(
  "requests",
  async (params, thunkAPI) => {
    try {
      const response = await getRequests(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getRequestDetailAsync = createAsyncThunk(
  "requestDetail",
  async (id, thunkAPI) => {
    try {
      const response = await getRequestDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const requestSlice = createSlice({
  name: "request",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearRequestDetail: (state) => {
      state.detail = undefined;
    },
    clearRequests: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getRequestsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getRequestsAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getRequestsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getRequests",
          };
        }
      })
      .addCase(getRequestDetailAsync.pending, (state) => {
        state.status = "detail_loading";
      })
      .addCase(getRequestDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload.data;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getRequestDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getRequestDetail",
          };
        }
      });
  },
});
export const selectRequests = (state) => state.request.list;
export const selectRequestDetail = (state) => state.request.detail;
export const requestStatus = (state) => state.request.status;
export const requestError = (state) => state.request.error;
export const requestType = (state) => state.request.type;

export const { clearRequestDetail, clearRequests, setIdleStatus } =
  requestSlice.actions;

export default requestSlice.reducer;
