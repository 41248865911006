import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getVerify, getVerifyDetail, postVerify, putVerify } from "./verifyAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
};

export const getVerifyAsync = createAsyncThunk(
  "verify",
  async (params, thunkAPI) => {
    try {
      const response = await getVerify(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getVerifyDetailAsync = createAsyncThunk(
  "verifyDetail",
  async (id, thunkAPI) => {
    try {
      const response = await getVerifyDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const postVerifyAsync = createAsyncThunk(
  "postVerify",
  async (data, thunkAPI) => {
    try {
      const response = await postVerify(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const putVerifyAsync = createAsyncThunk(
  "putVerify",
  async (data, thunkAPI) => {
    try {
      const response = await putVerify(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const verifySlice = createSlice({
  name: "verify",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearVerifyDetail: (state) => {
      state.detail = undefined;
    },
    clearVerify: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getVerifyAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getVerifyAsync.fulfilled, (state, action) => {
        state.list = action.payload.data;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getVerifyAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getVerify",
          };
        }
      })
      .addCase(getVerifyDetailAsync.pending, (state) => {
        state.status = "detail_loading";
      })
      .addCase(getVerifyDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload.data;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getVerifyDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getVerify",
          };
        }
      })
      .addCase(postVerifyAsync.pending, (state) => {
        state.status = "detail_loading";
      })
      .addCase(postVerifyAsync.fulfilled, (state, action) => {
        state.detail = action.payload.data;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(putVerifyAsync.pending, (state) => {
        state.status = "update_loading";
      })
      .addCase(putVerifyAsync.fulfilled, (state, action) => {
        state.detail = action.payload.data;
        message.success("Cập nhật thành công!");
        state.status = "done";
        state.error = undefined;
      })
      .addCase(putVerifyAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật thất bại!");
          state.error = {
            payload: action.payload,
            title: "putVerify",
          };
        }
      });
  },
});
export const selectVerify = (state) => state.verify.list;
export const selectVerifyDetail = (state) => state.verify.detail;
export const verifyStatus = (state) => state.verify.status;
export const verifyError = (state) => state.verify.error;

export const { clearVerifyDetail, clearVerify, setIdleStatus } =
  verifySlice.actions;

export default verifySlice.reducer;
