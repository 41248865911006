import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined
  );
};

const getVerify = (params) => {
  return axiosClient.get("member-verify-infos", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getVerifyDetail = (id) => {
  return axiosClient.get("member-verify-infos/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postVerify = (data) => {
  return axiosClient.post("member-verify-infos", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putVerify = (data) => {
  return axiosClient.put("member-verify-infos/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export { getVerify, getVerifyDetail, postVerify, putVerify };
