import axios from "axios";

let url = process.env.REACT_APP_API_NOTI;

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getNotis = (params) => {
  return axios.get(url + `master/notifies`, {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getNotiDetail = (id) => {
  return axios.get(url + `master/notifies/` + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postNoti = (data) => {
  return axios.post(url + `master/notifies`, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putNoti = (data) => {
  return axios.put(url + "master/notifies/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const exportNoti = (params, options) => {
  return axios.get(url + "notify/export", {
    params,
    ...options,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putNotiImage = (id, data) => {
  return axios.patch(url + "notify/" + id, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const deleteNotiImage = (id, fileUploadId) => {
  return axios.delete(`crm/notify/${id}/ + picture/${fileUploadId}`, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

async function updateNotiToken(data) {
  const req = axios.post(url + "crm/notifies/signup-token", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
  return req;
}

async function clearNotiToken(data) {
  const req = axios.put(url + "crm/notifies/clear-token", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
  return req;
}

const fetchNewNoti = (params) => {
  return axios.get(url + `crm/notifies/count-unread`, {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const fetchAllNoti = (params) => {
  return axios.get(url + `crm/notifies/all`, {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const updateReadNoti = (id, data) => {
  const req = axios.put(url + `crm/notifies/${id}/read`, data, {
    headers: { Authorization: "Bearer " + getToken() },
  });
  return req;
};

const seenAllNoti = (data) => {
  const req = axios.put(url + `crm/notifies/read-all`, data, {
    headers: { Authorization: "Bearer " + getToken() },
  });
  return req;
};

export {
  getNotis,
  getNotiDetail,
  postNoti,
  putNoti,
  exportNoti,
  putNotiImage,
  deleteNotiImage,
  fetchNewNoti,
  updateNotiToken,
  clearNotiToken,
  updateReadNoti,
  fetchAllNoti,
  seenAllNoti,
};
